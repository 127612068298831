import { AfterViewInit, Component, ElementRef, ViewChild, inject } from '@angular/core';
import { AnalyticsService } from '@pedix-workspace/pedixapp-core-services';
import { ScrollPositionService } from '@pedix-workspace/angular-utils';
import {
  Establishment,
  AdminPanelOptionType,
  AdminPanelLinksNavigation,
  PlanType,
} from '@pedix-workspace/utils';
import { EstablishmentService } from '../../catalog/establishment.service';
import {
  ListItemComponent,
  ListComponent,
  CardHeaderComponent,
  CardComponent,
} from '@pedix-workspace/angular-ui-blocks';
import { TagComponent } from '@pedix-workspace/angular-ui-alert';
import { SectionMenuComponent, OptionMenuComponent } from '@pedix-workspace/angular-ui-menu';
import { RouterLink } from '@angular/router';
import { SubscriptionAlertComponent } from '../subscription-alert/subscription-alert.component';

import { TranslocoDirective } from '@ngneat/transloco';
import { environment } from '../../../../environments/environment';
import { AppGuidedTourService } from '../../../app-guided-tour.service';
import {
  IconAdvertisementComponent,
  IconAnalyticsComponent,
  IconCatalogComponent,
  IconClockComponent,
  IconCouponsComponent,
  IconDesignComponent,
  IconFormComponent,
  IconGrowthComponent,
  IconHelpComponent,
  IconInfoComponent,
  IconInstagramComponent,
  IconIntegrationsComponent,
  IconOrdersComponent,
  IconPaymentComponent,
  IconQrCodeComponent,
  IconRocketComponent,
  IconSearchEngineComponent,
  IconShippingComponent,
  IconStatisticsComponent,
  IconStoreComponent,
  IconSubscriptionComponent,
  IconWebComponent,
  IconYoutubeComponent,
  IconUsersComponent,
} from '@pedix-workspace/angular-ui-icons';
import { AppFeaturesAndRolesService } from '../../../app-features-and-roles.service';

let isFirstTime = true;

@Component({
  selector: 'app-admin-home',
  templateUrl: './admin-home.component.html',
  styleUrls: ['./admin-home.component.scss'],
  standalone: true,
  imports: [
    TranslocoDirective,
    SubscriptionAlertComponent,
    CardComponent,
    CardHeaderComponent,
    OptionMenuComponent,
    RouterLink,
    SectionMenuComponent,
    TagComponent,
    ListComponent,
    ListItemComponent,
    IconCatalogComponent,
    IconOrdersComponent,
    IconStatisticsComponent,
    IconStoreComponent,
    IconClockComponent,
    IconDesignComponent,
    IconAdvertisementComponent,
    IconPaymentComponent,
    IconShippingComponent,
    IconFormComponent,
    IconSearchEngineComponent,
    IconAnalyticsComponent,
    IconIntegrationsComponent,
    IconQrCodeComponent,
    IconCouponsComponent,
    IconSubscriptionComponent,
    IconHelpComponent,
    IconInfoComponent,
    IconRocketComponent,
    IconYoutubeComponent,
    IconWebComponent,
    IconInstagramComponent,
    IconGrowthComponent,
    IconUsersComponent,
  ],
})
export class AdminHomeComponent implements AfterViewInit {
  private guidedTourService = inject(AppGuidedTourService);
  private analyticsService = inject(AnalyticsService);
  private establishmentService = inject(EstablishmentService);
  private scrollPosition = inject(ScrollPositionService);
  private featuresAndRolesService = inject(AppFeaturesAndRolesService);

  @ViewChild('contentWrapper') contentWrapper: ElementRef;

  get establishment(): Establishment | undefined {
    return this.establishmentService.currentEstablishment;
  }
  get subscription(): Establishment['subscription'] | undefined {
    return this.establishment.subscription;
  }
  get isAdvancedPlan(): boolean {
    return this.subscription?.plan === PlanType.ADVANCED;
  }
  get displaySubscriptionPaymentAlert() {
    return (
      this.subscription &&
      this.subscription.status === 'active' &&
      this.subscription.requiresPayment
    );
  }
  get helpCenterUrl() {
    return environment.helpCenterUrl;
  }
  get youtubeUrl() {
    return environment.youtubeUrl;
  }
  get websiteUrl() {
    return environment.landingUrl;
  }
  get instagramUrl() {
    return environment.instagramUrl;
  }
  get allowedFeatures() {
    return this.featuresAndRolesService.getAllowedFeatures();
  }

  ngAfterViewInit() {
    if (isFirstTime) {
      this.guidedTourService.beginAdminTour();
    }
    isFirstTime = false;

    this.contentWrapper.nativeElement.scrollTop = this.scrollPosition.get('admin');
  }

  onScroll() {
    this.scrollPosition.set('admin', this.contentWrapper.nativeElement.scrollTop);
  }

  clickOption(optionName: AdminPanelOptionType) {
    this.analyticsService.logEvent('adminpanel_option_clicked', { menu_option: optionName });
  }

  clickLinkNavigation(linkName: AdminPanelLinksNavigation) {
    if (linkName === 'subscriptionUpgrade') {
      this.analyticsService.logEvent('subscription_upgrade_clicked', {
        subscription_location: 'adminPanel',
      });
      return;
    }

    if (linkName === 'helpCenter') {
      this.analyticsService.logEvent('adminpanel_helpcenter_clicked', { device: 'desktop' });
      return;
    }

    this.analyticsService.logEvent('adminpanel_linksnavigation_clicked', {
      lateral_menu: linkName,
    });
  }

  goToHelpCenter() {
    this.analyticsService.logEvent('adminpanel_helpcenter_clicked', { device: 'mobile' });

    const link = document.createElement('a');

    link.href = this.helpCenterUrl;
    link.target = '_blank';

    document.body.appendChild(link);

    link.click();

    document.body.removeChild(link);
  }
}

import {
  ApplicationConfig,
  ENVIRONMENT_INITIALIZER,
  LOCALE_ID,
  importProvidersFrom,
} from '@angular/core';
import {
  PreloadingStrategy,
  provideRouter,
  withEnabledBlockingInitialNavigation,
} from '@angular/router';

import { appAdminRoutes } from './app-admin.routes';
import { BrowserModule, HammerModule } from '@angular/platform-browser';
import { LoggerModule } from 'ngx-logger';
import { provideToastr } from 'ngx-toastr';
import { provideFirebaseApp } from '@angular/fire/app';

import {
  getFirebaseApp,
  getFirebaseAuth,
  getFirebaseFirestore,
  getFirebaseFunctions,
  getFirebaseStorage,
} from './firebase/clients';

import { provideFunctions } from '@angular/fire/functions';
import { provideStorage } from '@angular/fire/storage';
import { provideFirestore } from '@angular/fire/firestore';
import { provideAuth } from '@angular/fire/auth';
import { CurrencyFormatService } from '@pedix-workspace/pedixapp-shared-pipes';
import { AppCurrencyFormatService } from './app-currency-format.service';
import { ModulePreloadManualStrategyService } from '@pedix-workspace/pedixapp-core-services';
import { AppEnvironmentService } from './app-environment.service';
import { AppUiFormConfig } from './app-ui-form.config';
import { AngularUiFormConfig } from '@pedix-workspace/angular-ui-form';
import { EnvironmentService } from '@pedix-workspace/angular-utils';
import { provideHttpClient, withInterceptorsFromDi } from '@angular/common/http';
import { provideAnimations } from '@angular/platform-browser/animations';
import { DatePipe, registerLocaleData } from '@angular/common';
import defaultLocaleData from '@angular/common/locales/es-419';
import { loadMessages, locale } from 'devextreme/localization';
import esMessages from 'devextreme/localization/messages/es.json';
import { environment } from '../environments/environment';
import { provideTransloco } from '@ngneat/transloco';
import { TranslocoHttpLoader } from './transloco/transloco-http-loader.module';
import {
  HERE_MAPS_SERVICE_API_KEY,
  UiHereMapsConfigService,
} from '@pedix-workspace/angular-ui-here-maps';
import { AppHereMapsConfigService } from './app-here-maps-config.service';
import { GOOGLE_MAPS_SERVICE_API_KEY } from '@pedix-workspace/angular-ui-map';

import { PixelModule } from 'ngx-multi-pixel';
import { LoadImageService } from 'ngx-image-cropper';
import { CustomLoadImageService } from '@pedix-workspace/angular-ui-image-upload';
import { UiFormReactiveConfigService } from '@pedix-workspace/angular-ui-form-reactive';

import { AppFormReactiveConfigService } from './app-form-reactive-config.service';
import { STRIPE_PUBLISHABLE_KEY, provideNgxStripe } from 'ngx-stripe';
import {
  NgxAuthFirebaseUIConfigToken,
  UserProvidedConfigToken,
  ngxAuthFirebaseUIConfigFactory,
} from '@pedix-workspace/angular-firebase-auth';

const DEFAULT_LOCALE = 'es-419';

export const appAdminConfig: ApplicationConfig = {
  providers: [
    provideFirebaseApp(() => getFirebaseApp()),
    provideAuth(() => getFirebaseAuth()),
    provideFirestore(() => getFirebaseFirestore()),
    provideStorage(() => getFirebaseStorage()),
    provideFunctions(() => getFirebaseFunctions()),
    importProvidersFrom(
      HammerModule,
      BrowserModule,
      LoggerModule.forRoot({
        serverLoggingUrl: '/logs',
        enableSourceMaps: true,
        level: environment.logger.logLevel,
        serverLogLevel: environment.logger.serverLogLevel,
        disableConsoleLogging: environment.logger.disableConsoleLogging,
      }),
      PixelModule.forRoot({ enabled: true, pixelId: [environment.pedixPixelId] }),
    ),
    { provide: LOCALE_ID, useValue: DEFAULT_LOCALE },
    { provide: CurrencyFormatService, useClass: AppCurrencyFormatService },
    { provide: PreloadingStrategy, useExisting: ModulePreloadManualStrategyService },
    { provide: EnvironmentService, useClass: AppEnvironmentService },
    { provide: AngularUiFormConfig, useClass: AppUiFormConfig },
    { provide: UiFormReactiveConfigService, useClass: AppFormReactiveConfigService },
    { provide: UiHereMapsConfigService, useClass: AppHereMapsConfigService },
    { provide: HERE_MAPS_SERVICE_API_KEY, useValue: environment.hereMaps.apiKey },
    { provide: GOOGLE_MAPS_SERVICE_API_KEY, useValue: environment.googleMaps.apiKey },
    { provide: LoadImageService, useClass: CustomLoadImageService },
    { provide: STRIPE_PUBLISHABLE_KEY, useValue: environment.stripeAdvertisingPublishableKey },
    provideNgxStripe(environment.stripeAdvertisingPublishableKey),
    {
      provide: ENVIRONMENT_INITIALIZER,
      multi: true,
      useValue: () => {
        registerLocaleData(defaultLocaleData, DEFAULT_LOCALE);

        // Devextreme localization
        loadMessages(esMessages);

        // Default locale; justomized in app-layout.component
        locale(DEFAULT_LOCALE);
      },
    },
    {
      provide: UserProvidedConfigToken,
      useValue: {
        enableFirestoreSync: false, // enable/disable autosync users with firestore
        toastMessageOnAuthSuccess: false, // whether to open/show a snackbar message on auth success - default : true
        toastMessageOnAuthError: false, // whether to open/show a snackbar message on auth error - default : true
        // authGuardFallbackURL: '/acceso', // url for unauthenticated users - to use in combination with canActivate feature on a route
        // authGuardLoggedInURL: '/inicio', // url for authenticated users - to use in combination with canActivate feature on a route
        passwordMaxLength: 60, // `min/max` input parameters in components should be within this range.
        passwordMinLength: 8, // Password length min/max in forms independently of each componenet min/max.
        // Same as password but for the name
        nameMaxLength: 50,
        nameMinLength: 2,
        // If set, sign-in/up form is not available until email has been verified.
        // Plus protected routes are still protected even though user is connected.
        guardProtectedRoutesUntilEmailIsVerified: false,
        enableEmailVerification: false, // default: true
      },
    },
    {
      provide: NgxAuthFirebaseUIConfigToken,
      useFactory: ngxAuthFirebaseUIConfigFactory,
      deps: [UserProvidedConfigToken],
    },
    DatePipe,
    provideHttpClient(withInterceptorsFromDi()),
    provideAnimations(),
    provideRouter(appAdminRoutes, withEnabledBlockingInitialNavigation()),
    provideTransloco({
      config: {
        availableLangs: ['es-MX', 'es-AR'],
        defaultLang: 'es-MX',
        fallbackLang: 'es-MX',
        // Remove this option if your application doesn't support changing language in runtime.
        reRenderOnLangChange: false,
        prodMode: environment.production,
        missingHandler: {
          // It will use the first language set in the `fallbackLang` property
          useFallbackTranslation: true,
        },
      },
      loader: TranslocoHttpLoader,
    }),
    provideToastr({
      timeOut: 6000,
      positionClass: 'toast-top-center',
      preventDuplicates: true,
      closeButton: true,
    }),
  ],
};

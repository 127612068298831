import { Injectable, inject } from '@angular/core';
import { productFruits } from 'product-fruits';
import { Router, NavigationEnd } from '@angular/router';
import { filter } from 'rxjs/operators';
import { environment } from '../environments/environment';
import { Establishment } from '@pedix-workspace/utils';

type ProductFruitsUser = {
  username: string;
  email: string;
  firstname?: string;
  lastname?: string;
  signUpAt?: string;
  role?: string;
  hmac?: {
    hash: string;
    expiration?: string;
  };
  props?: {
    [key: string]: string | number | string[];
  };
};

@Injectable({
  providedIn: 'root',
})
export class ProductFruitsService {
  private readonly router = inject(Router);

  initialize(establishment: Establishment): void {
    const pfUser: ProductFruitsUser = {
      username: establishment.slug,
      email: establishment.contactInfo.email,
      firstname: establishment.contactInfo.firstName,
      lastname: establishment.contactInfo.lastName,
      signUpAt: establishment.subscription?.createdAt?.toISOString(),
      props: {
        plan: establishment.subscription.plan,
        score: 3, // TODO: get score from backend
      },
    };

    this.initProductFruits(pfUser, 'es');
    this.setupRouteTracking();
  }

  private initProductFruits(pfUser: ProductFruitsUser, language: string): void {
    productFruits.init(environment.productFruitsWorkspaceCode, language, pfUser);
  }

  private setupRouteTracking(): void {
    this.router.events.pipe(filter(event => event instanceof NavigationEnd)).subscribe(() => {
      this.notifyPageChange();
    });
  }

  private notifyPageChange(): void {
    productFruits.safeExec($productFruits => {
      $productFruits.push(['pageChanged']);
    });
  }
}
